/*
 * Configuration
 */

export const ENGAGEMENT_LINK = '#share';

export const FORMSPREE_URL = 'https://formspree.io/f/xknaepag';

export const MOBILE_BREAKPOINT = 1200;

export const MENU = [
    { text: 'Welcome', href: '#welcome' },
    { text: 'About', href: '#vision' },
    { text: 'Process + Timeline', href: '#process' },
    { text: 'Resources', href: '#resources' },
    { text: 'Contact', href: '#share' },
];
