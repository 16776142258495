const firebaseConfig = {
    apiKey: "AIzaSyBd7LIxVmNDl6nbwzRsBxsQp6yoop9veW4",
    authDomain: "hamilton-college-engagement.firebaseapp.com",
    projectId: "hamilton-college-engagement",
    storageBucket: "hamilton-college-engagement.appspot.com",
    messagingSenderId: "664994298563",
    appId: "1:664994298563:web:b49046ce91d11c7d454076"
};

export default firebaseConfig;
